import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import { getTour } from "../Api/TourApi";
import { Image } from "cloudinary-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Banner from "../Components/Banner";

const CheckoutPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [tourInfo, setTourInfo] = useState({});
  const history = useHistory();
  const performance_id = props.match.params.performanceid;
  const current = new Date();

  const date =
    current.getMonth() +
    1 +
    "/" +
    current.getDate() +
    "/" +
    current.getFullYear();

  useEffect(() => {
    let cancelled = false;

    async function fetchTour() {
      const response = await getTour(props.match.params.slug);
      // console.log(response);
      if (!response.data[0]) {
        history.push("/");
      }
      if (!cancelled) {
        setTourInfo(response.data[0]["attributes"]); //0 index because same method for one or all
        setLoading(false);
      }
    }
    fetchTour();

    return () => {
      cancelled = true;
    };
  }, [props.match.params.slug, history]);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: tourInfo.title,
    //TODO USER REAL DATE INSTEAD OF TODAY
    startDate: date,
    // endDate: "2025-07-21T23:00-05:00",
    eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
    eventStatus: "https://schema.org/EventScheduled",
    location: {
      "@type": "Place",
      name: tourInfo.startLocationName
        ? tourInfo.startLocationName
        : tourInfo.address,
      address: {
        "@type": "PostalAddress",
        streetAddress: tourInfo.address,
        addressLocality: tourInfo.city,
        postalCode: tourInfo.zip,
        addressRegion: tourInfo.state,
        addressCountry: "US",
      },
    },
    image:
      "https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto" +
      tourInfo.picturePath,
    description: tourInfo.description,
    offers: {
      "@type": "Offer",
      url:
        "https://www.americanlegacytours.com/" +
        props.match.params.slug +
        "/checkout/" +
        props.match.params.performanceid,
      price: tourInfo.price,
      priceCurrency: "USD",
      availability: "https://schema.org/InStock",
      priceValidUntil: "12/31/" + current.getFullYear(),
    },
    performer: {
      "@type": "PerformingGroup",
      name: "American Legacy Tours",
    },
    organizer: {
      "@type": "Organization",
      name: "Amerian Legacy Tours",
      url: "https://www.americanlegacytours.com",
    },
  };

  return (
    <>
      {loading && (
        // <div className="App" style={{ height: "4000px" }}>
        //   Loading...
        // </div>
        <Row style={{ textAlign: "center", height: "4000px" }}>
          <Col xs="12">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {!loading && (
        <>
          <Helmet>
            <title>Purchase Ticket | American Legacy Tours</title>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
          </Helmet>
          <section
            className="pageTitle"
            style={{
              padding: "30px",
              color: "#ffffff",
              // height: "200px",
              backgroundImage: tourInfo.topPath
                ? "url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto" +
                  tourInfo.topPath +
                  "')"
                : "url('https://www.americanlegacytours.com/img/pages/page-title-bg.jpg')",
              backgroundPosition: "center",
            }}
          >
            <Container>
              <Col>
                <div className="titleTable">
                  <div className="titleTableInner">
                    <div
                      className="pageTitleInfo"
                      style={{ textAlign: "center" }}
                    >
                      <h1>{tourInfo.title}</h1>
                    </div>
                  </div>
                </div>
              </Col>
            </Container>
          </section>
          <Row style={{ margin: "10px" }}>
            <Col sm={3}>
              <div style={{ textAlign: "center" }}>
                <a href={"/" + tourInfo.pathTitle + "/buy"}>
                  <Image
                    cloudName="american-legacy-tours"
                    publicId={tourInfo.picturePath}
                    style={{ width: "95%" }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                </a>
                <br />
                <a href={"/" + tourInfo.pathTitle} className="btn">
                  See Tour Information
                </a>
                <br />
                <a href={"/" + tourInfo.pathTitle + "/buy"} className="btn">
                  See Other Dates
                </a>
              </div>
              <Banner/>
            </Col>
            <Col sm={9}>
              <iframe
                className="iframe-class"
                style={{ height: "2000px" }}
                src={
                  "https://americanlegacytours.thundertix.com/orders/new?performance_id=" +
                  performance_id
                }
                width="100%"
                height="150"
                frameBorder="0"
                marginWidth="0"
                marginHeight="0"
                scrolling="auto"
                title="calendar"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default CheckoutPage;
