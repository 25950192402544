import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Badge, Card, Col, Row, Spinner, ListGroup } from "react-bootstrap";
import { getTourAvailability } from "../../Api/TourApi";

const isMobile = window.screen.width < 600;

const AvailabilitySmall = (props) => {
  const [tourAvailability, setTourAvailability] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [moreLink, setMoreLink] = useState("");
  // const [daysAvailable, setDaysAvailable] = useState({});

  useEffect(() => {
    let cancelled = false;
    let availabilityList = [];
    setIsLoading(true);
    setTourAvailability([]);
    if (props.thundertixId) {
      async function fetchTourAvailability() {
        const response = await getTourAvailability(props.thundertixId);
        if (!cancelled && response) {
          if (response) {
            let theAvailableDays = {};
            Object.keys(response).map((i) => {
              theAvailableDays[response[i].id] = i.dayofweek;
              availabilityList.push(response[i]);
              if (response[i].totalShowings > 6) {
                setHasMore(true);
                setMoreLink(props.slug + "/buy");
              } else {
                setHasMore(false);
              }
              return null;
            });
            setIsLoading(false);
            // setDaysAvailable(theAvailableDays);
          }
          return setTourAvailability(availabilityList);
        }
      }
      fetchTourAvailability();
    }

    return () => {
      cancelled = true;
    };
  }, [props.thundertixId]);

  return (
    <Card
      style={{
        width: "100%",
        margin: "0",
        padding: "0",
        border: "none",
      }}
    >
      <Card.Body style={{ width: "100%", margin: "0", padding: "0", minHeight:310 }}>
        {/* <h6 className="mb-2 text-muted" style={{ textAlign: "center" }}>
          {daysAvailable &&
            Object.keys(daysAvailable).map((i) => {
              return (
                <Badge variant="secondary" style={{ margin: "2px" }}>
                  {daysAvailable[i] + "s"}
                </Badge>
              );
            })}
        </h6> */}
        <Card.Text>
          {isLoading && (
            <>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </>
          )}
          {!isLoading && tourAvailability.length <= 0 && (
            <ListGroup.Item>Check Back Soon</ListGroup.Item>
          )}
          {!isLoading &&
            tourAvailability &&
            tourAvailability.map((i) => {
              return (
                <a
                  href={
                    i.availability && i.availability !== 0
                      ? "/" + props.slug + "/checkout/" + i.performaceId
                      : null
                  }
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListGroup.Item className="availabilitydate" style={{ paddingLeft: 0, paddingRight:1 }}>
                    <span className="ml-auto">
                      {!isMobile && i.month + " " + i.day}
                      {isMobile && i.short}
                      <> - </>
                      {i.time}&nbsp;{i.ampm}
                    </span>
                    {isMobile && <br />}
                    {!isMobile && (
                      <Badge
                        variant={
                          i.availability > 7
                            ? "success"
                            : i.availability > 3
                            ? "warning"
                            : i.availability > 0
                            ? "danger"
                            : "secondary"
                        }
                        className="ml-2 float-right"
                        style={{
                          fontSize: "100%",
                          width: "77px",
                          marginRight:15,
                        }}
                      >
                        <div
                          style={{ textDecoration: "none", color: "#FFFFFF" }}
                        >
                          {i.availability > 7
                            ? "Available"
                            : i.availability <= 0
                            ? "Sold Out"
                            : i.availability + " left"}
                        </div>
                      </Badge>
                    )}
                    {isMobile && (
                      <Badge
                        variant={
                          i.availability > 7
                            ? "success"
                            : i.availability > 3
                            ? "warning"
                            : i.availability > 0
                            ? "danger"
                            : "secondary"
                        }
                        className="ml-2"
                        style={{
                          fontSize: "110%",
                        }}
                      >
                        <div
                          style={{ textDecoration: "none", color: "#FFFFFF" }}
                        >
                          {i.availability > 7
                            ? "Available"
                            : i.availability <= 0
                            ? "Sold Out"
                            : i.availability + " left"}
                        </div>
                      </Badge>
                    )}
                  </ListGroup.Item>
                </a>
              );
            })}
          {/* {isLoading && (
            <Row style={{ textAlign: "center" }}>
              <Col>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          )} */}
          {/* {!isLoading && tourAvailability.length <= 0 && (
            <Row
              style={{
                padding: "2px",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <Col>Coming Soon</Col>
            </Row>
          )} */}
          {/* {hasMore && (
            <Row style={{ padding: "2px", textAlign: "center" }}>
              <Col>
                <a href={moreLink}>
                  <Badge variant={"secondary"}>See More</Badge>
                </a>
              </Col>
            </Row>
          )} */}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
export default AvailabilitySmall;
