import React from "react";
import { Button, Card, Col, ListGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Image, Placeholder, Transformation } from "cloudinary-react";
import "bootstrap/dist/css/bootstrap.min.css";
import AvailabilitySmall from "../Components/Tour/AvailabilitySmall";

const Tour = (props) => {
  const isMobile = window.screen.width < 600;
  const current = new Date();
  return (
    props.tour.enabled && (
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              name: props.tour.title,
              description: props.tour.description,
              brand: {
                "@type": "Brand",
                name: "American Legacy Tours",
              },
              image: `https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto${props.tour.picturePath}`,
              offers: {
                "@type": "Offer",
                url: `https://www.americanlegacytours.com/${props.tour.pathTitle}`,
                price: props.tour.price,
                priceCurrency: "USD",
                availability: "https://schema.org/InStock",
                priceValidUntil: `12/31/${current.getFullYear()}`,
              },
            })}
          </script>
        </Helmet>
        <Col
          xl={3} // THIS WAS GONE
          lg={4} // THIS WAS 3
          md={6} // THIS WAS 4
          sm={6}
          xs={6}
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <Card style={{ marginBottom: "20px" }}>
            <a href={props.tour.pathTitle}>
              <Image
                className="w-100"
                cloudName="american-legacy-tours"
                publicId={props.tour.picturePath}
                loading="lazy"
                fetch_format="auto"
                quality="auto"
                dpr="auto"
                crop="scale"
                title={props.tour.title}
              >
                <Placeholder type="predominant" />
                <Transformation height="240" width="340" />
              </Image>
            </a>
            <Card.Body
              style={{
                margin: "0",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Card.Title style={{ display: "none" }}>
                {props.tour.title}
              </Card.Title>
              <Button
                outline
                color="secondary"
                block
                href={props.tour.pathTitle}
              >
                Learn More
              </Button>
            </Card.Body>
            {isMobile && (
              <ListGroup className="list-group">
                <AvailabilitySmall
                  thundertixId={props.tour.thundertixId}
                  slug={props.tour.pathTitle}
                />
              </ListGroup>
            )}
            {!isMobile && (
              <ListGroup
                className="list-group"
              >
                <AvailabilitySmall
                  thundertixId={props.tour.thundertixId}
                  slug={props.tour.pathTitle}
                />
              </ListGroup>
            )}
          </Card>
        </Col>
      </>
    )
  );
};
export default Tour;
